export function handlePromoBanner() {
    const banner = document.querySelector('.promo-banner');

    if (!banner) {
        return;
    }

    import('vanilla-marquee').then((marquee) => {
        const speed = window.screen.width < 1024 ? 75 : 100;
        const textContainer = document.querySelector('.promo-banner-text');

        const marqueeInstance = new marquee.default(textContainer, {
            speed: speed,
            pauseOnHover: true,
            delayBeforeStart: 0,
            duplicated: true,
            gap: window.screen.width - textContainer.querySelector('p').offsetWidth
        });

        marqueeInstance.el.querySelectorAll('p').forEach(text => text.style.visibility = 'visible');
    });
}
