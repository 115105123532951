if (document.querySelectorAll('.burger')) {
    document.querySelectorAll('.burger').forEach((e) => {
        e.addEventListener('click', () => {
            document.querySelector('.hamburger').style.transform = "translateX(0)";
            document.querySelector('.overlay').style.opacity = "0.3";
            document.querySelector('.overlay').style.zIndex = "90";
            document.querySelector('.overlay').style.display = "block";
        })
    })

    document.querySelector('.hamburger .close').addEventListener('click', () => {
        document.querySelector('.hamburger').style.transform = "translateX(-100%)";
        document.querySelector('.overlay').style.opacity = "0";
        document.querySelector('.overlay').style.zIndex = "-1";
        document.querySelector('.overlay').style.display = "none";
    })
}

/** GROUP SWITCHER */
const groupCheckbox = document.querySelector('.group-switcher input[type="checkbox"]');

if (groupCheckbox) {
    groupCheckbox.addEventListener('change', () => document.location.href = groupCheckbox.checked ?
        groupCheckbox.dataset.proPath :
        groupCheckbox.dataset.partPath
    );
}

/** CURRENCY SWITCHER */
const currencyCheckbox = document.querySelector('.currency-switcher input[type="checkbox"]');

if (currencyCheckbox) {
    currencyCheckbox.addEventListener('change', () => document.location.href = currencyCheckbox.checked ?
        currencyCheckbox.dataset.eurPath :
        currencyCheckbox.dataset.usdPath
    );
}

/** LOCALE SWITCHER */
const localeCheckbox = document.querySelector('.locale-switcher input[type="checkbox"]');

if (localeCheckbox) {
    localeCheckbox.addEventListener('change', () => document.location.href = localeCheckbox.checked ?
        localeCheckbox.dataset.frPath :
        localeCheckbox.dataset.enPath
    );

}
