export function handleCartWidget() {
    const cartWidget = document.querySelector('[data-js-cart-widget]');

    if (!cartWidget) {
        console.error('Cart widget not found, missing data-js-cart-widget attribute on container');
        return;
    }

    const button = cartWidget.querySelector('[data-js-cart-widget-button]');
    if (!button) {
        console.error('Cart widget button not found, missing data-js-cart-widget-button attribute on container');
        return;
    }

    const popup = cartWidget.querySelector('[data-js-cart-widget-popup]');
    if (!popup) {
        console.error('Cart widget popup not found, missing data-js-cart-widget-popup attribute on container');
        return;
    }

    button.addEventListener('click', (e) => {
        popup.classList.toggle('hidden');
    })
}