import './scss/index.scss';

// JS
import './js/sylius/app.js';
import './js/menu.js';
import './js/pages/common.js';
import { setFlashCloseEvent } from './js/utils/notification.js';
import { bgImgLazyLoading } from './js/utils/perf.js';
import { handleBreadcrumbTextColor } from './js/utils/background-brightness.js';
import { handleOwlCarousel } from './js/utils/owl-carousel.js';

console.log('APP SHOP');

setFlashCloseEvent();
handleOwlCarousel('.team-partenaire');
handleOwlCarousel('.certifications-carousel');
handleOwlCarousel('.owl-favorites-products');

if (document.querySelector('#productsDefaultCheckbox') && document.querySelector('#productsCheckboxButton')) {
    const checkbox = document.querySelector('#productsDefaultCheckbox');
    const button = document.querySelector('#productsCheckboxButton');
    button.addEventListener('click', () => {
        if (checkbox.classList.contains('hidden')) {
            button.style.borderColor = "rgb(215, 215, 215)";
            button.style.borderBottom = "none";
            button.style.borderRadius = "0.375rem 0.375rem 0 0";
        } else {
            button.style.borderColor = "rgb(0, 0, 0)";
            button.style.borderBottom = "1px solid rgb(0,0,0)";
            button.style.borderRadius = "0.375rem";
        }
    })
}

if (document.querySelector('#brandsDefaultCheckbox') && document.querySelector('#brandsCheckboxButton')) {
    const checkbox = document.querySelector('#brandsDefaultCheckbox');
    const button = document.querySelector('#brandsCheckboxButton');
    button.addEventListener('click', () => {
        if (checkbox.classList.contains('hidden')) {
            button.style.borderColor = "rgb(215, 215, 215)";
            button.style.borderBottom = "none";
            button.style.borderRadius = "0.375rem 0.375rem 0 0";
        } else {
            button.style.borderColor = "rgb(0, 0, 0)";
            button.style.borderBottom = "1px solid rgb(0,0,0)";
            button.style.borderRadius = "0.375rem";
        }
    })
}

if (document.querySelector('#routinesDefaultCheckbox') && document.querySelector('#routinesCheckboxButton')) {
    const checkbox = document.querySelector('#routinesDefaultCheckbox');
    const button = document.querySelector('#routinesCheckboxButton');
    button.addEventListener('click', () => {
        if (checkbox.classList.contains('hidden')) {
            button.style.borderColor = "rgb(215, 215, 215)";
            button.style.borderBottom = "none";
            button.style.borderRadius = "0.375rem 0.375rem 0 0";
        } else {
            button.style.borderColor = "rgb(0, 0, 0)";
            button.style.borderBottom = "1px solid rgb(0,0,0)";
            button.style.borderRadius = "0.375rem";
        }
    })
}

if (document.querySelector('#priceDefaultCheckbox') && document.querySelector('#priceCheckboxButton')) {
    const checkbox = document.querySelector('#priceDefaultCheckbox');
    const button = document.querySelector('#priceCheckboxButton');
    button.addEventListener('click', () => {
        if (checkbox.classList.contains('hidden')) {
            button.style.borderColor = "rgb(215, 215, 215)";
            button.style.borderBottom = "none";
            button.style.borderRadius = "0.375rem 0.375rem 0 0";
        } else {
            button.style.borderColor = "rgb(0, 0, 0)";
            button.style.borderBottom = "1px solid rgb(0,0,0)";
            button.style.borderRadius = "0.375rem";
        }
    })
}

bgImgLazyLoading();

document.addEventListener('DOMContentLoaded', () => {
    handleBreadcrumbTextColor();
});