const getImageBrightness = (imageSrc, callback) => {
    let colorSum = 0;
    const img = document.createElement('img');
    img.src = imageSrc;
    img.style.display = 'none';
    img.crossOrigin = 'anonymous';

    document.body.appendChild(img);

    img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(this, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        let r, g, b, avg;

        for (let i = 0, l = data.length; i < l; i += 4) {
            r = data[i];
            g = data[i + 1];
            b = data[i + 2];

            avg = Math.floor((r + g + b) / 3);
            colorSum += avg;
        }

        const brightness = Math.floor(colorSum / (this.width * this.height));
        callback(brightness);
    }
};

export function handleBreadcrumbTextColor() {
    const banner = document.querySelector('.banner-main');


    if (!banner) {
        return;
    }

    const breadcrumb = banner.querySelector('.menu_resp');

    if (!breadcrumb) {
        return;
    }

    const style = banner.currentStyle || window.getComputedStyle(banner, false);

    if (style.backgroundImage === 'none') {
        return;
    }

    const backgroundImage = style.backgroundImage.slice(4, -1).replace(/"/g, '');

    getImageBrightness(backgroundImage, (brightness) => {
        breadcrumb.classList.remove('dark-text');
        breadcrumb.classList.remove('light-text');

        if (brightness < 225 / 1.5) {
            breadcrumb.classList.toggle('dark-text');
        } else {
            breadcrumb.classList.toggle('light-text');
        }
    });
}
